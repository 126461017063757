/**
 * GET_MEMBER_ASSESSMENTS_RESULTS
 */
import { put, select } from 'redux-saga/effects'
import { getMemberAssessmentResultsAPI } from '../../../APIs/MemberAssessment'
import MemberAssessmentActions from '../../../Stores/MemberAssessment/Actions'


function* getMemberAssessmentResults({ memberAssessmentId, accessToken }) {
  try {
    yield put(MemberAssessmentActions.getMemberAssessmentResultsLoading())

    if (accessToken) {
      const response = yield getMemberAssessmentResultsAPI(memberAssessmentId, accessToken)
      const { status, data } = response
      console.log('response getMemberAssessmentResults', memberAssessmentId, data)
      if (status === 200) {
        yield put(MemberAssessmentActions.getMemberAssessmentResultsSuccess(data))
      } else {
        throw Error('No Thrive domains found')
      }
    }

  } catch (error) {
    console.log(error)
    yield put(MemberAssessmentActions.getMemberAssessmentResultsFailure(error))
  }
}

export default getMemberAssessmentResults
