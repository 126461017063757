
import getMembers from './GetMembers/getMembers'
import getMembersSuccess from './GetMembers/getMembersSuccess'
import getMembersFailure from './GetMembers/getMembersFailure'
//
import addMember from './AddMember/addMember'
import addMemberSuccess from './AddMember/addMemberSuccess'
import addMemberFailure from './AddMember/addMemberFailure'
//
import getMemberProfile from './GetMemberProfile/getMemberProfile'
import getMemberProfileSuccess from './GetMemberProfile/getMemberProfileSuccess'
import getMemberProfileFailure from './GetMemberProfile/getMemberProfileFailure'
//
import updateMemberProfile from './UpdateMemberProfile/updateMemberProfile'
import updateMemberProfileSuccess from './UpdateMemberProfile/updateMemberProfileSuccess'
import updateMemberProfileFailure from './UpdateMemberProfile/updateMemberProfileFailure'


export default {
  //
  getMembers,
  getMembersSuccess,
  getMembersFailure,
  //
  addMember,
  addMemberSuccess,
  addMemberFailure,
  //
  getMemberProfile,
  getMemberProfileSuccess,
  getMemberProfileFailure,
  //
  updateMemberProfile,
  updateMemberProfileSuccess,
  updateMemberProfileFailure,

}
