import { put, select } from 'redux-saga/effects'
import { getAssessmentAPI } from '../../../APIs/Assessments'
import AssessmentsActions from '../../../Stores/Assessments/Actions'

function* getAssessment({ assessmentId, accessToken }) {
  try {
    yield put(AssessmentsActions.getAssessmentLoading())

    if (accessToken) {
      const response = yield getAssessmentAPI(assessmentId, accessToken)
      const { status, data } = response
      console.log('response', response)
      if (status === 200) {
        yield put(AssessmentsActions.getAssessmentSuccess(data))
      } else {
        throw Error('No Thrive domains found')
      }
    }


  } catch (error) {
    console.log(error)
    yield put(AssessmentsActions.getAssessmentFailure(error))
  }
}

export default getAssessment
