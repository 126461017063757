import { put, select } from 'redux-saga/effects'
// import { authorize } from 'react-native-app-auth'
// import jwt_decode from "jwt-decode";
import { getUsers } from '../../../APIs/Users'
import MembersActions from '../../../Stores/Members/Actions'
// import { signInSuccess } from '../../../Stores/Auth/Select'
// import { signUpSuccess } from '../../../Stores/Auth/Select'
// import { profileInfo } from '../../../Stores/Account/Select'

function* getMembers({ id,
  firstName,
  lastName,
  email,
  dateOfBirth,
  phoneNumber,
  pageNumber,
  pageSize, accessToken }) {
  try {
    yield put(MembersActions.getMembersLoading())

    // const signInData = yield select(signInSuccess)
    // const signUpData = yield select(signUpSuccess)

    // const userData = signInData || signUpData

    // if (userData) {
    //   const { accessToken, idToken } = userData
    //   const idTokenDecoded = jwt_decode(idToken)
    console.log('Member Saga:', firstName,
      lastName,
      email,
      dateOfBirth,
      phoneNumber,
      pageNumber, accessToken)
    if (accessToken) {
      const respThr = yield getUsers(id, firstName, lastName, email, dateOfBirth, phoneNumber, pageNumber, pageSize, accessToken)
      const { status } = respThr
      if (status === 200) {
        const data = { data: respThr.data, pagination: respThr.headers['x-pagination'] }
        console.log('New Data', data)
        yield put(MembersActions.getMembersSuccess(data))
      } else {
        throw Error('No Thrive user found')
      }
    }
    // }

  } catch (error) {
    console.log(error)
    yield put(MembersActions.getMembersFailure(error))
  }
}

export default getMembers
