import { all, takeLatest } from 'redux-saga/effects'
import { MemberAssessmentTypes } from '../Stores/MemberAssessment/Actions'
import MemberAssessment from './MemberAssessment'

export default function* root() {
  yield all([
    takeLatest(MemberAssessmentTypes.GET_MEMBER_ASSESSMENT, MemberAssessment.getMemberAssessment),
    takeLatest(MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_SUCCESS, MemberAssessment.getMemberAssessmentSuccess),
    takeLatest(MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_FAILURE, MemberAssessment.getMemberAssessmentFailure),
    //
    takeLatest(MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_RESULTS, MemberAssessment.getMemberAssessmentResults),
    takeLatest(MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_RESULTS_SUCCESS, MemberAssessment.getMemberAssessmentResultsSuccess),
    takeLatest(MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_RESULTS_FAILURE, MemberAssessment.getMemberAssessmentResultsFailure),
  ])
}
