import { thriveDevReq, thriveDevUrl } from '..'
import { getConfigs, getConfigsImg } from '../apiHelpers'
import axios from 'axios'


export const insertUsers = async (user, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/ThriveMember`,
    "method": "POST",
    "headers": getConfigs(token),
    "data": user
  })
  console.log('AAA Insert:', user, token, response)
  return response
}

export const updateUsers = async (thriveMemberId, userData, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/ThriveMember?thriveMemberId=${thriveMemberId}`,
    "method": "PATCH",
    "headers": getConfigsImg(token),
    "data": userData
  })
  console.log('AAA Update:', thriveMemberId, userData, token, response)
  return response
}

export const getUser = async (identityProviderUserId, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/ThriveMember/${identityProviderUserId}`,
    "method": "GET",
    "headers": getConfigs(token)
  })

  return response
}

export const getUsers = async (id, firstName, lastName, email, dateOfBirth, phoneNumber, pageNumber = 1, pageSize = 10, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/ThriveMember`,
    "params": { id, firstName, lastName, email, dateOfBirth, phoneNumber, pageNumber, pageSize },
    "method": "GET",
    "headers": getConfigs(token)
  })
  return response
}