import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Notes from '../../../../Assets/notes.png'

const SurveyListItem = (props) => {
  const { item, onSelect, lastModified } = props
  // const lastModified = item.memberAssessmentId ? new Date(item?.modifiedOn).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) : ''
  return <Box onClick={onSelect} sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${item.isFlagged ? '#9B0909' : '#D9D9D9'}`,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '15px'
  }}>

    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <img src={Notes} />
      <Box sx={{
        marginLeft: '16px'
      }}>
        <Typography sx={{
          fontFamily: 'Merriweather',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '120%',
          color: '#003657'
        }}>{item.assessmentName}</Typography>
        <Typography sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          ineHeight: '150%',
          color: '#897A7A'

        }}>{lastModified ? `Last updated on ${lastModified}` : 'Not Started'}</Typography>
      </Box>
    </Box>
    <KeyboardArrowRightIcon fontSize='large' color='#000000' />
  </Box>
}

export default SurveyListItem