
import React from 'react'
import { Grid, Typography, OutlinedInput } from '@mui/material'
import { formLabelInputStyle, formInputStyle } from './styles'

import { Field } from 'react-final-form'
const LabeledInput = ({ name, label }) => {
  return <Field name={name}>
    {({ input, meta }) => (
      <Grid item xs={10}>
        <Typography sx={formLabelInputStyle()}>{label}</Typography>
        <OutlinedInput name={name} sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
      </Grid>
    )}
  </Field>
}

export default LabeledInput