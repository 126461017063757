import { put, select } from 'redux-saga/effects'
// import { authorize } from 'react-native-app-auth'
// import jwt_decode from "jwt-decode";
import { getUser } from '../../../APIs/Users'
import MembersActions from '../../../Stores/Members/Actions'
// import { signInSuccess } from '../../../Stores/Auth/Select'
// import { signUpSuccess } from '../../../Stores/Auth/Select'
// import { profileInfo } from '../../../Stores/Account/Select'

function* getMemberProfile({ id, accessToken }) {
  try {
    yield put(MembersActions.getMemberProfileLoading())

    // const signInData = yield select(signInSuccess)
    // const signUpData = yield select(signUpSuccess)

    // const userData = signInData || signUpData

    // if (userData) {
    // const { accessToken, idToken } = userData
    // const idTokenDecoded = jwt_decode(idToken)
    if (id && accessToken) {
      console.log('Saga', id)
      const respThr = yield getUser(id, accessToken)
      const { status, data } = respThr
      if (status === 200) {
        yield put(MembersActions.getMemberProfileSuccess(data))
      } else {
        throw Error('No Thrive user found')
      }
    }
    // }

  } catch (error) {
    console.log(error)
    yield put(MembersActions.getMemberProfileFailure(error))
  }
}

export default getMemberProfile
