export const getConfigs = (token) => {
  console.log("Helper", token)
  return {
    // headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Access-Control-Expose-Headers': 'x-pagination'
    // }
  }
}
export const getConfigsImg = (token) => {
  return {
    // headers: {
    'Content-Type': 'application/json',
    'Embedded-Image': 1,
    'Authorization': `Bearer ${token}`
    // }
  }
}