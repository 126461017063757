// GET_ASSESSMENTS_SUMMARY
import { put, select } from 'redux-saga/effects'
import { getAssessmentsSummaryAPI } from '../../../APIs/AssessmentsSummary'
import AssessmentsSummaryActions from '../../../Stores/AssessmentsSummary/Actions'
// import { signInSuccess } from '../../../Stores/Auth/Select'
// import { signUpSuccess } from '../../../Stores/Auth/Select'
// import { profileInfo } from '../../../Stores/Account/Select'

function* getAssessmentsSummary({ thriveMemberId, accessToken }) {

  try {
    yield put(AssessmentsSummaryActions.getAssessmentsSummaryLoading())

    // const signInData = yield select(signInSuccess)
    // const signUpData = yield select(signUpSuccess)

    // const userData = signInData || signUpData

    // if (userData) {
    //   const { accessToken } = userData
    if (accessToken) {
      const response = yield getAssessmentsSummaryAPI(thriveMemberId, accessToken)
      const { status, data } = response
      console.log('response', response)
      if (status === 200) {
        yield put(AssessmentsSummaryActions.getAssessmentsSummarySuccess(data))
      } else {
        throw Error('No Thrive domains found')
      }
      // }
    }

  } catch (error) {
    console.log(error)
    yield put(AssessmentsSummaryActions.getAssessmentsSummaryFailure(error))
  }
}

export default getAssessmentsSummary
