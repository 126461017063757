import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    //
    getAssessmentsSummary: ['thriveMemberId', 'accessToken'], // GET_ASSESSMENTS_SUMMARY
    getAssessmentsSummaryLoading: null, // GET_ASSESSMENTS_SUMMARY_LOADING
    getAssessmentsSummarySuccess: ['data'], // GET_ASSESSMENTS_SUMMARY_SUCCESS
    getAssessmentsSummaryFailure: ['error'], // GET_ASSESSMENTS_SUMMARY_FAILURE

  },
  { prefix: 'ASSESSEMENTSSUMMARY_' }
)

export const AssessmentsSummaryTypes = Types

export default Creators
