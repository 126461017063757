import { thriveDevReq, thriveDevUrl } from '..'
import { getConfigs } from '../apiHelpers'
import axios from 'axios'



// export const getAssessmentsAPI = (token) => {
//   return thriveDevReq.get(`/Assessments`, {}, getConfigs(token))
// }
export const getAssessmentsAPI = async (token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/Assessments`,
    "method": "GET",
    "headers": getConfigs(token)
  })
  return response
}
export const getRapidScreenerAPI = (token) => {
  return thriveDevReq.get(`/Assessments/rapidscreener`, {}, getConfigs(token))
}
// export const getAssessmentAPI = (assessmentId, token) => {
//   return thriveDevReq.get(`/Assessments/${assessmentId}`, {}, getConfigs(token))
// }

export const getAssessmentAPI = async (assessmentId, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/Assessments/${assessmentId}`,
    "method": "GET",
    "headers": getConfigs(token)
  }).then(response => {
    return response
  })
    .catch(e => {
      console.log(e)
    })

  return response
}
