import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Box, Grid, Avatar, Typography, Chip, IconButton } from '@mui/material';
import { average, msalLowestValue, scoringClassification, ifs, and, or, msalSubtractive, percentageCalculation, relativePercentageWhole, highestValue, multiply, addValue, sum } from '../../Helpers/SurveyJsCustomFunc'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssessmentActions from '../../Stores/Assessments/Actions'
import { Survey } from "survey-react-ui";
import "survey-core/modern.min.css";
import { getLoggedUserData } from '../../Services/MsalServices'
import { useMsal, useAccount } from '@azure/msal-react';
import Cookies from 'js-cookie';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

FunctionFactory.Instance.register("average", average);
FunctionFactory.Instance.register("msalLowestValue", msalLowestValue);
FunctionFactory.Instance.register("scoringClassification", scoringClassification);
FunctionFactory.Instance.register("ifs", ifs);
FunctionFactory.Instance.register("and", and);
FunctionFactory.Instance.register("or", or);
FunctionFactory.Instance.register("msalSubtractive", msalSubtractive);
FunctionFactory.Instance.register("percentageCalculation", percentageCalculation);
FunctionFactory.Instance.register("relativePercentageWhole", relativePercentageWhole);
FunctionFactory.Instance.register("highestValue", highestValue);
FunctionFactory.Instance.register("multiply", multiply);
FunctionFactory.Instance.register("addValue", addValue);
FunctionFactory.Instance.register("sum", sum);

const Assessment = ({ assessment, getMemberAssessment, member, getAssessmentData }) => {
  const [survey, setSurvey] = useState(null)
  // const [memberAssessmentId, setMemberAssessmentId] = useState(undefined)
  const [progress, setProgress] = useState('')
  const route = useLocation()
  const { assessmentId, thriveMemberId } = useParams()
  const [token, setToken] = useState(window.token || null)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {});
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()
  // alert(Cookies.get('auth'))
  console.log('Param', assessmentId, thriveMemberId, useParams(), route.pathname?.includes('/app'))
  useEffect(() => {
    if (route.pathname?.includes('/app')) {
      document.addEventListener('message', (data) => {
        // if (data) {
        //getAssessment(data.data);
        console.log('Data', data)
        setToken(data?.data || window.token || Cookies.get('auth'))
        // }
      })
      window.addEventListener('message', (data) => {
        // if (data) {
        //getAssessment(data.data);
        console.log('Data', data)
        setToken(data?.data || window.token || Cookies.get('auth'))
        // }
      })
    }
    // if (window.isNativeApp) {
    //   alert("Is Native ")
    //   alert(window.token)
    // }
  }, [])
  useEffect(() => {
    if (!userData && inProgress === InteractionStatus.None && !route.pathname?.includes('/app')) {
      getLoggedUserData(instance, account, InteractionRequiredAuthError, setUserData)
    }
  }, [instance, account, inProgress])

  useEffect(() => {
    const today = new Date()
    const tokenHasExp = userData?.expiresOn?.getTime() < today?.getTime()
    if (tokenHasExp && !route.pathname?.includes('/app')) {
      instance.logoutRedirect().catch((error) => console.log(error));
    }
  }, [userData])

  let memberAssessmentId;
  const baseUrl = process.env.REACT_APP_THRIVE_API_URL;
  const getAssessment = async () => {
    // alert('getAssessment' + ' ' + assessmentId + ' ' + thriveMemberId)
    try {
      const [memberAssessment, assessment] = await Promise.all([
        fetch(`${baseUrl}/MemberAssessment/`, {
          method: 'POST',
          body: JSON.stringify({ assessmentId: assessmentId, thriveMemberId: thriveMemberId }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token || userData?.accessToken}`
          }
        }),
        fetch(`${baseUrl}/Assessments/${assessmentId}?thriveMemberId=${thriveMemberId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token || userData?.accessToken}`
          }
        })
      ]);
      console.log(memberAssessment, assessment)
      if (!memberAssessment.ok || !assessment.ok) {
        throw new Error('Assessment or MemberAssessment not found');
      }
      const [assessmentData, memberAssessmentData] = await Promise.all([assessment.json(), memberAssessment.json()])
      console.log('memberAssessmentData', memberAssessmentData)
      let response;
      if (memberAssessmentData) {
        response = memberAssessmentData.response;
        memberAssessmentId = memberAssessmentData.id
        // setMemberAssessmentId(memberAssessmentData.id)
      }
      console.log(response)
      loadSurvey(assessmentData.definition, response);
    } catch (error) {
      console.error(error);
    }
  }

  const setProgressBarText = (sender, options) => {
    const questionCount = options.questionCount;
    const answeredQuestionCount = options.answeredQuestionCount;
    options.text = Math.round((100 * options.answeredQuestionCount) / options.questionCount) + "% Completed";
    setProgress(options.text)
    sendDataToReactNativeApp(false, options.text)
  }

  const loadSurvey = (definition, data) => {
    const surveyData = new Model(definition);
    if (data) {
      surveyData.data = JSON.parse(data) || {};
      surveyData.currentPageNo = surveyData.data.pageNo || 0;
    }
    surveyData.onComplete.add(survey => sendDataToServer(survey, true, memberAssessmentId));
    surveyData.onPartialSend.add(survey => sendDataToServer(survey, false, memberAssessmentId));
    surveyData.onProgressText.add(setProgressBarText);
    setSurvey(surveyData)
    console.log(surveyData, data)
  }

  const sendDataToReactNativeApp = async (complete, progress) => {
    if (complete) {
      window.ReactNativeWebView?.postMessage(complete);
    } else {
      window.ReactNativeWebView?.postMessage(progress);
    }

  };

  const create = (data) => {
    fetch(`${baseUrl}/MemberAssessment/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token || userData.accessToken}`
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        memberAssessmentId = data.id
        // setMemberAssessmentId(data.id)
        console.log('Success:', data.id, memberAssessmentId);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const update = (data) => {
    fetch(`${baseUrl}/MemberAssessment/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token || userData.accessToken}`
      },
      body: JSON.stringify(data),
    })
      //.then((response) => response.json())
      .then((data) => {
        console.log('Success:', memberAssessmentId);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const sendDataToServer = (survey, complete, memberAssessmentId) => {
    const surveyData = survey.data;
    surveyData.pageNo = survey.currentPageNo;
    console.log('Create or Update', memberAssessmentId)
    if (memberAssessmentId === undefined) {
      const data = {
        assessmentId: assessmentId,
        content: surveyData,
        thriveMemberId: thriveMemberId,
        isAssessmentComplete: complete
      };
      create(data);
    }
    else {
      const data = {
        id: memberAssessmentId,
        assessmentId: assessmentId,
        content: surveyData,
        thriveMemberId: thriveMemberId,
        isAssessmentComplete: complete
      };
      update(data)
    }
    sendDataToReactNativeApp(complete, "0% Completed")
  }

  useEffect(() => {
    const theToken = token || userData?.accessToken || window.token
    if (theToken) {
      getAssessment()
    }
    if (assessmentId && theToken) {
      getAssessmentData(assessmentId, theToken)
    }
  }, [token, userData, window.token])

  // useEffect(() => {
  //   const theToken = token || userData?.accessToken || window.token
  //   if (assessmentId && theToken) {
  //     getAssessmentData(assessmentId, theToken)
  //   }
  // }, [token, userData, window.token])

  if (!survey) { return null }

  if (route.pathname.includes('/app')) {

    return <Survey model={survey} />
  }

  return (
    <Grid container spacing={2} sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 10
    }}>
      <Grid container direction="row" xs={12} sx={{
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}>

        <IconButton
          onClick={() => { navigate(-1) }}
        >
          <ArrowBackIcon htmlColor={'#000'} />
        </IconButton>
        <Avatar src={`data:image/png;base64,${member?.profilePicture}`} sx={{ width: 32, height: 32, marginRight: 1, marginLeft: 0.75 }} />
        <Typography sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 20,
          marginRight: 1
        }} >
          {`${member?.firstName} ${member?.lastName}`}
        </Typography>
        <Typography sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontSize: 14,
          paddingTop: .2,
          opacity: .45,
          color: '#000'
        }}>
          {assessment?.name}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="row" xs={12} sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: 3.063,
          paddingTop: 2.313
        }}>
          <Typography sx={{
            fontFamily: 'Merriweather',
            fontWeight: 700,
            fontSize: 18,
            lineHeight: '150%',
            textTransform: 'uppercase'
          }}>{assessment?.name}</Typography>
          <Chip label={progress} sx={{
            background: '#081E3F',
            color: '#FFFFFF',
            marginLeft: 1.438,
            borderRadius: 2,
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: 14
          }} />
        </Grid>

        <Survey model={survey} />

      </Grid>

    </Grid >);
}



const mapStateToProps = (state) => {
  console.log(state)
  return {
    assessment: state.assessments.getAssessmentSuccess,
    member: state.members.getMemberProfileSuccess,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAssessmentData: (assessmentId, accessToken) => dispatch(AssessmentActions.getAssessment(assessmentId, accessToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Assessment)