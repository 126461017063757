import { put, select } from 'redux-saga/effects'
// import { authorize } from 'react-native-app-auth'
// import jwt_decode from "jwt-decode";
import { updateUsers } from '../../../APIs/Users'
import MembersActions from '../../../Stores/Members/Actions'
// import { profileInfo } from '../../../Stores/Account/Select'
// import { signInSuccess } from '../../../Stores/Auth/Select'
// import { signUpSuccess } from '../../../Stores/Auth/Select'

function* updateMemberProfile({ thriveMemberId, userData, accessToken }) {
  try {
    yield put(MembersActions.updateMemberProfileLoading())
    // const signInData = yield select(signInSuccess)
    // const signUpData = yield select(signUpSuccess)
    // const user = signInData || signUpData
    console.log(thriveMemberId, userData)
    if (userData && accessToken) {
      const formatedData = []
      Object.keys(userData).forEach(function (key, index) {
        formatedData.push({ op: "replace", Path: `/${key}`, value: userData[key] });
      });

      const respThive = yield updateUsers(thriveMemberId, JSON.stringify(formatedData), accessToken)
      yield put(MembersActions.updateMemberProfileSuccess(respThive?.data))
    }
  } catch (error) {
    console.log(error)
    yield put(MembersActions.updateMemberProfileFailure(error))
  }
}

export default updateMemberProfile
