import React, { useEffect } from 'react'
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Box, Grid, Button } from '@mui/material'
import { loginRequest } from '../../Configs/authConfig'
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../../Assets/logo.svg'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://thrivesurvey-dev.medicine.fiu.edu/">
        Thrive by FIU
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const SignIn = () => {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  useEffect(() => {
    if (isAuthenticated) {

    } else {
      // handleLoginRedirect()
    }

  }, [])
  return <ThemeProvider theme={theme}>

    <CssBaseline />
    <Grid container>
      <Grid item xs={6} sx={{
        height: '100vh',
        backgroundColor: '#081E3F',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}>
        <Box>
          <img src={logo} />
        </Box>
      </Grid>
      <Grid item xs={6} sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}>
        <Box>
          <Typography sx={{
            fontFamily: 'Merriweather',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px',
            color: '#003657',
            marginBottom: '10px'
          }}>Hi, Welcome to Thrive!</Typography>
          <Typography sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '25px',
            color: '#464646',
            marginBottom: '37px'
          }}>Please click below to log in.</Typography>
          <Button variant='contained' onClick={handleLoginRedirect} sx={{
            width: '314px',
            height: '56px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            background: '#003657',
            textTransform: 'capitalize'
          }} endIcon={<ArrowForwardIosIcon />}>Log in</Button>
        </Box>
      </Grid>
    </Grid>
    {/* <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={Logo} style={{
          width: '50%',
          marginBottom: '30px'
        }} />
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
      </Typography>
        <Box sx={{ mt: 1 }}>
          <Button
            onClick={handleLoginRedirect}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
        </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} /> */}

  </ThemeProvider >
  // return <Grid container justifyContent='center' alignItems='center'>
  //   <Grid item xs={8}>
  //     <Box>
  //       <Button variant='contained' onClick={handleLoginRedirect}>Login</Button>
  //     </Box>
  //   </Grid>
  // </Grid>
}

export default SignIn