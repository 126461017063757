import { Model } from "survey-core";


export function average(params) {
  let sum = 0;
  let count = 0;
  for (let i = 0; i < params.length; i++) {
    const value = params[i];
    if (typeof value === 'number' && !isNaN(value)) {
      sum += value;
      count++;
    }
  }
  return count > 0 ? sum / count : 0;
}

export function msalLowestValue(params) {
  let lowestValue = undefined;
  for (let i = 0; i < params.length; i++) {
    const value = params[i];
    if (typeof value === 'number' && !isNaN(value)) {
      if (lowestValue === undefined || value < lowestValue) {
        lowestValue = value;
      }
    }
  }
  return lowestValue !== undefined ? lowestValue : 0;
}

export function scoringClassification(params) {
  const score = params[0];
  for (let i = 1; i < params.length; i += 2) {
    const threshold = params[i];
    const classification = params[i + 1];
    if (score <= threshold) {
      return classification;
    }
  }
  return null;
}

export function ifs(params) {
  for (let i = 1; i < params.length; i += 2) {
    const condition = params[i - 1];
    const value = params[i];
    if (condition) {
      if (typeof value === 'function') {
        return value();
      } else {
        return value;
      }
    }
  }
  return null;
}

export function and(params) {
  for (let i = 0; i < params.length; i++) {
    if (!params[i]) {
      return false;
    }
  }
  return true;
}

export function or(params) {
  for (let i = 0; i < params.length; i++) {
    if (params[i]) {
      return true;
    }
  }
  return false;
}

export function msalSubtractive(params) {
  const startingValue = params[0];
  const step = params[1];
  const lowerBound = params[2];

  let result = startingValue;
  for (let i = 3; i < params.length; i++) {
    if (params[i] !== undefined) {
      while (result - step >= lowerBound) {
        result -= step;
      }
    }
  }

  return result;
}

export function percentageCalculation(params) {
  const numerator = params[0];
  const denominator = params[1];
  const decimalPlaces = params[2];

  const percentage = (numerator / denominator) * 100;

  return parseFloat(percentage.toFixed(decimalPlaces));
}

export function relativePercentageWhole(questionName) {
  var question = this.survey.getQuestionByName(questionName);
  var choices = question.visibleChoices;
  var selectedChoices = question.getSelectedChoices();
  var percentage = 0;

  if (choices.length > 0 && selectedChoices.length > 0) {
    percentage = percentageCalculation(selectedChoices.length, choices.length, 0);
  }

  return percentage;
}

export function highestValue(params) {
  let highest = Number.NEGATIVE_INFINITY;
  for (let i = 0; i < params.length; i++) {
    if (typeof params[i] === "number" && !isNaN(params[i]) && params[i] > highest) {
      highest = params[i];
    }
  }
  return highest;
}

export function multiply(params) {
  let product = 1;
  for (let i = 0; i < params.length; i++) {
    if (typeof params[i] === "number") {
      product *= params[i];
    }
  }
  return product;
}

export function addValue(params) {
  if (params[1] === undefined) {
    return params[0];
  } else {
    return params[0] + params[2];
  }
}

export function sum(params) {
  let total = 0;
  for (let i = 0; i < params.length; i++) {
    if (typeof params[i] === 'number') {
      total += params[i];
    }
  }
  return total;
}