import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './InitialState'
import { AssessmentsSummaryTypes } from './Actions'


const getAssessmentsSummaryLoading = (state) => ({
  ...state,
  getAssessmentsSummaryLoading: true,
  getAssessmentsSummarySuccess: null,
  getAssessmentsSummaryFailure: null
})

const getAssessmentsSummarySuccess = (state, { data }) => ({
  ...state,
  assessmentsSummary: data,
  getAssessmentsSummaryLoading: false,
  getAssessmentsSummarySuccess: data,
  getAssessmentsSummaryFailure: null,
})

const getAssessmentsSummaryFailure = (state, { error }) => ({
  ...state,
  assessmentsSummary: {},
  getAssessmentsSummaryLoading: false,
  getAssessmentsSummarySuccess: null,
  getAssessmentsSummaryFailure: error.message
})




export const reducer = createReducer(INITIAL_STATE, {

  [AssessmentsSummaryTypes.GET_ASSESSMENTS_SUMMARY]: getAssessmentsSummaryLoading,
  [AssessmentsSummaryTypes.GET_ASSESSMENTS_SUMMARY_SUCCESS]: getAssessmentsSummarySuccess,
  [AssessmentsSummaryTypes.GET_ASSESSMENTS_SUMMARY_FAILURE]: getAssessmentsSummaryFailure,
})
