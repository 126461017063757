import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './InitialState'
import { MembersTypes } from './Actions'


const getMembersLoading = (state) => ({
  ...state,
  getMembersLoading: true,
  getMembersSuccess: null,
  getMembersFailure: null
})

const getMembersSuccess = (state, { data }) => ({
  ...state,
  members: data,
  getMembersLoading: false,
  getMembersSuccess: data,
  getMembersFailure: null,
})

const getMembersFailure = (state, { error }) => ({
  ...state,
  members: {},
  getMembersLoading: false,
  getMembersSuccess: null,
  getMembersFailure: error.message
})

const addMemberLoading = (state) => ({
  ...state,
  addMemberLoading: true,
  addMemberSuccess: null,
  addMemberFailure: null
})

const addMemberSuccess = (state, { user }) => ({
  ...state,
  user,
  addMemberLoading: false,
  addMemberSuccess: user,
  addMemberFailure: null,
})

const addMemberFailure = (state, { error }) => ({
  ...state,
  user: {},
  addMemberLoading: false,
  addMemberSuccess: null,
  addMemberFailure: error.message
})

const getMemberProfileLoading = (state) => ({
  ...state,
  getMemberProfileLoading: true,
  getMemberProfileSuccess: null,
  getMemberProfileFailure: null
})

const getMemberProfileSuccess = (state, { user }) => ({
  ...state,
  user,
  getMemberProfileLoading: false,
  getMemberProfileSuccess: user,
  getMemberProfileFailure: null,
})

const getMemberProfileFailure = (state, { error }) => ({
  ...state,
  user: {},
  getMemberProfileLoading: false,
  getMemberProfileSuccess: null,
  getMemberProfileFailure: error.message
})

const updateMemberProfileLoading = (state) => ({
  ...state,
  updateMemberProfileLoading: true,
  updateMemberProfileSuccess: null,
  updateMemberProfileFailure: null
})

const updateMemberProfileSuccess = (state, { user }) => ({
  ...state,
  user,
  updateMemberProfileLoading: false,
  updateMemberProfileSuccess: user,
  updateMemberProfileFailure: null,
})

const updateMemberProfileFailure = (state, { error }) => ({
  ...state,
  user: {},
  updateMemberProfileLoading: false,
  updateMemberProfileSuccess: null,
  updateMemberProfileFailure: error.message
})


export const reducer = createReducer(INITIAL_STATE, {

  [MembersTypes.GET_MEMBERS_LOADING]: getMembersLoading,
  [MembersTypes.GET_MEMBERS_SUCCESS]: getMembersSuccess,
  [MembersTypes.GET_MEMBERS_FAILURE]: getMembersFailure,
  //
  [MembersTypes.ADD_MEMBER_LOADING]: addMemberLoading,
  [MembersTypes.ADD_MEMBER_SUCCESS]: addMemberSuccess,
  [MembersTypes.ADD_MEMBER_FAILURE]: addMemberFailure,
  //
  [MembersTypes.GET_MEMBER_PROFILE_LOADING]: getMemberProfileLoading,
  [MembersTypes.GET_MEMBER_PROFILE_SUCCESS]: getMemberProfileSuccess,
  [MembersTypes.GET_MEMBER_PROFILE_FAILURE]: getMemberProfileFailure,
  //
  [MembersTypes.UPDATE_MEMBER_PROFILE_LOADING]: updateMemberProfileLoading,
  [MembersTypes.UPDATE_MEMBER_PROFILE_SUCCESS]: updateMemberProfileSuccess,
  [MembersTypes.UPDATE_MEMBER_PROFILE_FAILURE]: updateMemberProfileFailure,
})
