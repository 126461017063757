import { thriveDevReq, thriveDevUrl } from '..'
import { getConfigs } from '../apiHelpers'
import axios from 'axios'

// export const getMemberAssessmentAPI = (token, memberAssessmentId) => {
//   return thriveDevReq.get(`/MemberAssessment/${memberAssessmentId}`, {}, getConfigs(token))
// }

export const getMemberAssessmentAPI = async (memberAssessmentId, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/MemberAssessment/${memberAssessmentId}`,
    "method": "GET",
    "headers": getConfigs(token)
  })
    .then(response => {

      return response
    })
    .catch(e => {
      console.log(e)
    })
  return response
}

// export const getMemberAssessmentResultsAPI = (token, memberAssessmentId) => {
//   return thriveDevReq.get(`/MemberAssessment/results/${memberAssessmentId}`, {}, getConfigs(token))
// }

export const getMemberAssessmentResultsAPI = async (memberAssessmentId, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/MemberAssessment/results/${memberAssessmentId}`,
    "method": "GET",
    "headers": getConfigs(token)
  })
    .then(response => {

      return response
    })
    .catch(e => {
      console.log(e)
    })
  return response
}

