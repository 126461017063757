import React from 'react'
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/react';
import Logo from '../../Assets/sideBar-logo.png'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import './styles.css'
const drawerWidth = 300;



const SideBar = (props) => {
  // const { collapseSidebar } = useProSidebar();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const currentRoute = useLocation()
  const navigate = useNavigate();
  console.log('Route', currentRoute.pathname)

  const drawer = (
    <div >
      <Toolbar sx={{
        justifyContent: 'center',
        marginBottom: 5
      }}>
        <div className='ImgContainer'>
          <img
            src={Logo}
            alt={'Thrive'}
            loading="lazy"
            style={{ width: 158, objectFit: 'scale-down' }}
          />
        </div>
      </Toolbar>
      <Divider />
      <List>

        <ListItem disablePadding onClick={() => navigate('/')}>
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary='Members' />
          </ListItemButton>
        </ListItem>

      </List>
      <Box pt={1.9} sx={{
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        paddingBottom: 0.625,
        background: '#004068',
        height: 65,
        width: drawerWidth,
        // paddingTop: 1.188
      }}>
        <KeyboardArrowLeftIcon fontSize='large' />
      </Box>
    </div>
  );

  return <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    aria-label="mailbox folders"
    className="Drawer"
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          background: '#081E3F',
        },
        '& .MuiList-root': {
          paddingLeft: 3,
          paddingRight: 3
        },
        '& .MuiListItem-root': {
          '& :hover': {
            background: '#CC0066',
            borderRadius: 2,
            width: 267
          }
        },
        '& .MuiListItemText-root': {
          color: '#FFFFFF'
        },
        '& .MuiListItemIcon-root': {
          minWidth: 33
        },
        '& .MuiSvgIcon-root': {
          color: '#FFFFFF',
          marginRight: 0.75
        }
      }
      }
    >
      {drawer}
    </Drawer>
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          background: '#081E3F',
        },
        '& .MuiList-root': {
          paddingLeft: 3,
          paddingRight: 3
        },
        '& .MuiListItem-root': {
          '& :hover': {
            background: '#CC0066',
            borderRadius: 2,
            // width: 267
          }
        },
        '& .MuiListItemText-root': {
          color: '#FFFFFF'
        },
        '& .MuiListItemIcon-root': {
          minWidth: 33
        },
        '& .MuiSvgIcon-root': {
          color: '#FFFFFF',
          marginRight: 0.75
        }
      }}
      open
    >
      {drawer}
    </Drawer>
  </Box>

  // return (
  //   <div style={{ display: 'flex', height: '100%' }}>
  //     <Sidebar>
  //       <Menu>
  //         <MenuItem> Documentation</MenuItem>
  //         <MenuItem> Calendar</MenuItem>
  //         <MenuItem> E-commerce</MenuItem>
  //       </Menu>
  //     </Sidebar>
  //   </div>
  // );
}

export default SideBar