/**
 * GET_MEMBERS_FAILURE
 */
function* getMembersFailure({ error }) {
  console.log('Failure', error)
  // NavigationService.navigate('PathSelection')
  // NavigationService.navigate('Dashboard')
}

export default getMembersFailure
