import { put, select } from 'redux-saga/effects'
import { getMemberAssessmentAPI } from '../../../APIs/MemberAssessment'
import MemberAssessmentActions from '../../../Stores/MemberAssessment/Actions'
// import { signInSuccess } from '../../../Stores/Auth/Select'
// import { signUpSuccess } from '../../../Stores/Auth/Select'
// import { profileInfo } from '../../../Stores/Account/Select'

function* getMemberAssessment({ memberAssessmentId, accessToken }) {
  try {
    yield put(MemberAssessmentActions.getMemberAssessmentLoading())

    // const signInData = yield select(signInSuccess)
    // const signUpData = yield select(signUpSuccess)

    // const userData = signInData || signUpData

    // if (userData) {
    //   const { accessToken } = userData
    if (accessToken) {
      const response = yield getMemberAssessmentAPI(memberAssessmentId, accessToken)
      const { status, data } = response
      console.log('response getMemberAssessment', memberAssessmentId, data)
      if (status === 200) {
        yield put(MemberAssessmentActions.getMemberAssessmentSuccess(data))
      } else {
        throw Error('No Thrive domains found')
      }
    }
    // }

  } catch (error) {
    console.log(error)
    yield put(MemberAssessmentActions.getMemberAssessmentFailure(error))
  }
}

export default getMemberAssessment
