const isEmpty = (val) => {
  if (val === undefined) {
    return true
  }

  if (typeof (val) === 'function' || typeof (val) === 'number' || typeof (val) === 'boolean' || Object.prototype.toString.call(val) === '[object Date]') {
    return false
  }

  if (val === null || val.length === 0) {
    return true
  }

  if (typeof (val) === 'object') {
    // empty object
    let r = true
    for (const f in val) {
      if (val.hasOwnProperty(f)) { // eslint-disable-line
        r = false
      }
    }

    return r
  }

  return false
}

export default isEmpty