import axios from 'axios'

export const thriveDevUrl = process.env.REACT_APP_THRIVE_API_URL

console.log('BASE URL', thriveDevUrl)

export const thriveDevReq = axios.create({
  baseURL: thriveDevUrl,
  withCredentials: true,
})

export default {
  thriveDevReq
}
