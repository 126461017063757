import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    //
    getMemberAssessment: ['memberAssessmentId', 'accessToken'], // GET_MEMBER_ASSESSMENT
    getMemberAssessmentLoading: null, // GET_MEMBER_ASSESSMENT_LOADING
    getMemberAssessmentSuccess: ['data'], // GET_MEMBER_ASSESSMENT_SUCCESS
    getMemberAssessmentFailure: ['error'], // GET_MEMBER_ASSESSMENT_FAILURE
    //
    getMemberAssessmentResults: ['memberAssessmentId', 'accessToken'], // GET_MEMBER_ASSESSMENT_RESULTS
    getMemberAssessmentResultsLoading: null, // GET_MEMBER_ASSESSMENT_RESULTS_LOADING
    getMemberAssessmentResultsSuccess: ['data'], // GET_MEMBER_ASSESSMENT_RESULTS_SUCCESS
    getMemberAssessmentResultsFailure: ['error'], // GET_MEMBER_ASSESSMENT_RESULTS_FAILURE

  },
  { prefix: 'MEMBER_ASSESSMENT_' }
)

export const MemberAssessmentTypes = Types

export default Creators
