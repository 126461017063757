// import Crashes, { ExceptionModel } from 'appcenter-crashes';
/**
 * GET_MEMBER_ASSESSMENT_RESULTS_FAILURE
 */
function* getMemberAssessmentResultsFailure({ error }) {
  // const exceptionModel1 = ExceptionModel.createFromError(error);
  // Crashes.trackError(exceptionModel1, null, null);
  console.log('Success', error)
}

export default getMemberAssessmentResultsFailure