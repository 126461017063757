import { put } from 'redux-saga/effects'
// import AccountActions from '../../../Stores/Account/Actions'
/**
 * UPDATE_MEMBER_PROFILE_SUCCESS
 */
function* updateMemberProfileSuccess({ user }) {
  // yield put(AccountActions.getProfileInfo())
  console.log('Success', user)
}

export default updateMemberProfileSuccess
