import { all, takeLatest } from 'redux-saga/effects'
import { AssessmentsTypes } from '../Stores/Assessments/Actions'
import Assessments from './Assessments'

export default function* root() {
  yield all([
    takeLatest(AssessmentsTypes.GET_RAPID_SCREENER, Assessments.getRapidScreener),
    takeLatest(AssessmentsTypes.GET_RAPID_SCREENER_SUCCESS, Assessments.getRapidScreenerSuccess),
    takeLatest(AssessmentsTypes.GET_RAPID_SCREENER_FAILURE, Assessments.getRapidScreenerFailure),
    //
    takeLatest(AssessmentsTypes.GET_ASSESSMENT, Assessments.getAssessment),
    takeLatest(AssessmentsTypes.GET_ASSESSMENT_SUCCESS, Assessments.getAssessmentSuccess),
    takeLatest(AssessmentsTypes.GET_ASSESSMENT_FAILURE, Assessments.getAssessmentFailure),
  ])
}
