import { put, select } from 'redux-saga/effects'
import { getRapidScreenerAPI } from '../../../APIs/Assessments'
import AssessmentsActions from '../../../Stores/Assessments/Actions'
// import { signInSuccess } from '../../../Stores/Auth/Select'
// import { signUpSuccess } from '../../../Stores/Auth/Select'
// import { profileInfo } from '../../../Stores/Account/Select'

function* getRapidScreener() {

  try {
    yield put(AssessmentsActions.getRapidScreenerLoading())

    // const signInData = yield select(signInSuccess)
    // const signUpData = yield select(signUpSuccess)

    // const userData = signInData || signUpData

    // if (userData) {
    // const { accessToken } = userData
    // if (accessToken) {
    const response = yield getRapidScreenerAPI()
    const { status, data } = response
    console.log('response', response)
    if (status === 200) {
      yield put(AssessmentsActions.getRapidScreenerSuccess(data))
    } else {
      throw Error('No Thrive domains found')
    }
    // }
    // }

  } catch (error) {
    console.log(error)
    yield put(AssessmentsActions.getRapidScreenerFailure(error))
  }
}

export default getRapidScreener
