import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    //
    getRapidScreener: null, // GET_RAPID_SCREENER
    getRapidScreenerLoading: null, // GET_RAPID_SCREENER_LOADING
    getRapidScreenerSuccess: ['data'], // GET_RAPID_SCREENER_SUCCESS
    getRapidScreenerFailure: ['error'], // GET_RAPID_SCREENER_FAILURE
    //
    getAssessment: ['assessmentId', 'accessToken'], // GET_ASSESSMENT
    getAssessmentLoading: null, // GET_ASSESSMENT_LOADING
    getAssessmentSuccess: ['data'], // GET_ASSESSMENT_SUCCESS
    getAssessmentFailure: ['error'], // GET_ASSESSMENT_FAILURE

  },
  { prefix: 'ASSESSMENTS_' }
)

export const AssessmentsTypes = Types

export default Creators
