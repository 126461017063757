import getRapidScreener from './GetRapidScreener/getRapidScreener'
import getRapidScreenerSuccess from './GetRapidScreener/getRapidScreenerSuccess'
import getRapidScreenerFailure from './GetRapidScreener/getRapidScreenerFailure'
//
import getAssessment from './GetAssessment/getAssessment'
import getAssessmentSuccess from './GetAssessment/getAssessmentSuccess'
import getAssessmentFailure from './GetAssessment/getAssessmentFailure'


export default {
  //
  getRapidScreener,
  getRapidScreenerSuccess,
  getRapidScreenerFailure,
  //
  getAssessment,
  getAssessmentSuccess,
  getAssessmentFailure,
}
