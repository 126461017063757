import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Grid, Avatar, Typography, Chip, Tabs, Tab, OutlinedInput, InputAdornment, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Form, Field } from 'react-final-form'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import isEmpty from '../../Helpers/isEmpty'
import { dateIsExpired } from '../../Helpers/dateIsExpired'
import isValidDate from '../../Helpers/isValidDate'
import { formatDate } from '../../Helpers/formatDate'
import MembersActions from '../../Stores/Members/Actions'
import Notes from '../../Assets/notes.png'
import checkMark from '../../Assets/check-mark-green.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Images from '../../Theme/Images'
import { diff } from 'deep-object-diff';
import AssessmentsSummaryActions from '../../Stores/AssessmentsSummary/Actions'
import MemberAssessmentActions from '../../Stores/MemberAssessment/Actions'
import SurveyListItem from './Components/SurveyListItem'
import CloseIcon from '@mui/icons-material/Close';
import { getLoggedUserData } from '../../Services/MsalServices'
import { useMsal, useAccount } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const formInputStyle = () => {
  return {
    width: '100%',
    minHeight: 46,
    border: "1px solid #E5E7EB",
    marginBottom: 1.375,
    fontSize: '15px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '17.58px'

  }
}
const formLabelInputStyle = () => {
  return {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: 1.125,
    letterSpacing: 0.005,
    color: '#475569',
    marginBottom: 0.938
  }
}

const MemberProfile = ({ addMember, getMemberProfile, member, updateMemberProfile, getAssessmentsSummary, assessmentsSummary, memberLoading, getMemberAssessmentResults, surveyResults }) => {
  const [value, setValue] = React.useState(Number(window.sessionStorage.getItem('currentTab')) || 0);
  const [open, setOpen] = React.useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(false)
  const [type, setType] = useState('')
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {});
  const [userData, setUserData] = useState(null)



  const { id, added } = useParams()

  const lastModified = (item) => item.memberAssessmentId ? new Date(item?.modifiedOn).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) : ''

  useEffect(() => {
    const activeTab = Number(window.sessionStorage.getItem('currentTab'))
    console.log(activeTab)
    setValue(activeTab)
  }, [])

  useEffect(() => {
    if (!userData && inProgress === InteractionStatus.None) {
      getLoggedUserData(instance, account, InteractionRequiredAuthError, setUserData)
    }
  }, [instance, account])

  useEffect(() => {
    const today = new Date()
    const tokenHasExp = userData?.expiresOn?.getTime() < today?.getTime()
    if (tokenHasExp) {
      instance.logoutRedirect().catch((error) => console.log(error));
    }
  }, [userData])

  useEffect(() => {
    getMemberProfile(id, userData?.accessToken)
  }, [userData])
  useEffect(() => {
    if (member?.id) {
      getAssessmentsSummary(member.id, userData?.accessToken)
    }
  }, [member])

  useEffect(() => {
    window.sessionStorage.setItem('currentTab', value)
  }, [value])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (values) => {
    const updatedValues = diff(initialValues, values)
    updateMemberProfile(member.id, updatedValues, userData?.accessToken)
    getMemberProfile(id, userData?.accessToken)
  }

  const validate = (values) => {
    const errors = {}
    if (isEmpty(values.firstName)) {
      errors.firstName = 'Incorrect entry.'
    }

    if (isEmpty(values.lastName)) {
      errors.lastName = 'Incorrect entry.'
    }

    if (isEmpty(values.dateOfBirth)) {
      errors.dateOfBirth = 'Incorrect entry.'
    } else {
      const dob = new Date(values.dateOfBirth)
      if (`${dob}` === 'Invalid Date' || !isValidDate(values.dateOfBirth)) {
        errors.dateOfBirth = 'Incorrect entry.'
      } else if (dob.getFullYear() < 1925) {
        errors.dateOfBirth = 'Incorrect entry.'
      } else {
        const today = new Date()
        let age = today.getFullYear() - dob.getFullYear()
        const m = today.getMonth() - dob.getMonth()

        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--
        }

        if (age < 18) {
          errors.dateOfBirth = 'Incorrect entry.'
        }
      }
    }

    if (isEmpty(values.addressLine1)) {
      errors.addressLine1 = 'Incorrect entry.'
    }
    if (isEmpty(values.city)) {
      errors.city = 'Incorrect entry.'
    }
    // if (isEmpty(values.state)) {
    //   errors.state = 'State is required.'
    // }
    if (isEmpty(values.zipcode)) {
      errors.zipcode = 'Incorrect entry.'
    } else if (
      (values.zipcode.includes('-') && values.zipcode.length !== 10) ||
      (!values.zipcode.includes('-') && values.zipcode.length !== 5)
    ) {
      errors.zipcode = 'Incorrect entry.'
    }
    if (isEmpty(values.phoneNumber)) {
      errors.phoneNumber = 'Incorrect entry.'
    }
    if (isEmpty(values.email)) {
      errors.email = 'Incorrect entry.'
    }

    return errors
  }

  const initialValues = useMemo(() => ({
    firstName: member?.firstName,
    lastName: member?.lastName,
    dateOfBirth: formatDate(new Date(member?.dateOfBirth)),
    addressLine1: member?.addressLine1,
    addressLine2: member?.addressLine2,
    city: member?.city,
    state: member?.state,
    zipcode: member?.zipcode,
    phoneNumber: member?.phoneNumber,
    email: member?.email
  }), [member])
  console.log('Member: ', member)
  const getType = (type) => {
    switch (type) {
      case 'Not Started':
        return 'Begin Survey'
      case 'Started':
        return 'Resume Survey'
      case 'Completed':
        return 'Update Survey'
      default:
        return 'Update Survey'
    }
  }

  if (memberLoading) {
    return <Grid container spacing={2} sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 10
    }}>
      <Grid item xs={8}>
        <Typography>Loading</Typography>
      </Grid>
    </Grid>
  }
  return (
    <Grid container spacing={2} sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 10
    }}>
      <Grid container direction="row" xs={12} sx={{
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}>

        <IconButton
          onClick={() => { navigate(-1) }}
        >
          <ArrowBackIcon htmlColor={'#000'} />
        </IconButton>

        <Avatar src={`data:image/png;base64,${member?.profilePicture}`} sx={{ width: 32, height: 32, marginRight: 1, marginLeft: 0.75 }} />
        <Typography sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 20,
          marginRight: 1
        }} >
          {`${member?.firstName} ${member?.lastName}`}
        </Typography>
        <Typography sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontSize: 14,
          paddingTop: .2,
          opacity: .45,
          color: '#000'
        }}>
          Profile
      </Typography>
        {added === 'true' ? <Box sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '5px 17px',
          marginLeft: '82.75px'
        }}>
          <img src={checkMark} style={{ width: 15, height: 15, marginRight: 15 }} />
          <Typography>Member successfully added</Typography>
        </Box> : null}
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab sx={{
                textTransform: 'capitalize',
                borderBottom: 1,
                borderColor: 'divider'
              }} label="Profile" {...a11yProps(0)} />
              <Tab sx={{
                textTransform: 'capitalize',
                borderBottom: 1,
                borderColor: 'divider'
              }} label="Surveys" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Form
              onSubmit={handleSubmit}
              validate={validate}
              initialValues={initialValues}
              // validateOnBlur={true}
              render={({ handleSubmit, form }) => {
                console.log(form.getState())
                return <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>First Name</Typography>
                        <OutlinedInput name='firstName' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="lastName">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Last Name</Typography>
                        <OutlinedInput name='lastName' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="dateOfBirth">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Date of Birth</Typography>
                        <OutlinedInput name='dateOfBirth' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>


                  <Field name="addressLine1">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Address</Typography>
                        <OutlinedInput name='addressLine1' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="addressLine2">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Suite/ Apt</Typography>
                        <OutlinedInput name='suite' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="city">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>City</Typography>
                        <OutlinedInput name='city' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="state">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>State</Typography>
                        <OutlinedInput name='state' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="zipcode">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Zip Code</Typography>
                        <OutlinedInput name='zipCode' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}
                  </Field>
                  <Field name="phoneNumber">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Phone Number</Typography>
                        <OutlinedInput name='phoneNumber' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Field name="email">
                    {({ input, meta }) => (
                      <Grid item xs={10}>
                        <Typography sx={formLabelInputStyle()}>Email</Typography>
                        <OutlinedInput name='email' sx={formInputStyle()} {...input} error={meta.touched && meta.error ? true : false} />
                      </Grid>
                    )}

                  </Field>
                  <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='submit' sx={{
                      background: `linear-gradient(96.32deg, #17A7B1 0%, #99DCDF 103.77%)`,
                      borderRadius: 11.25,
                      color: '#FFFFFF',
                      fontFamily: 'Work Sans',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: 16,
                      minWidth: 64,
                      padding: "8px 15px",
                      marginBottom: '34px'
                    }} onClick={handleSubmit}>Update</Button>
                  </Grid>
                </Grid>
              }} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {assessmentsSummary?.find(as => as.memberAssessmentId) ? <Typography sx={{
              paddingLeft: '20px',
              marginBottom: '20px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              ineHeight: '150%',
              color: 'rgba(0, 0, 0, 0.85)'
            }}>Started</Typography> : null}
            {assessmentsSummary?.map(as => {
              if (as.memberAssessmentId && !as.isComplete) {
                return <SurveyListItem item={as} onSelect={() => {
                  setSelectedSurvey(as)
                  setType('Started')
                  getMemberAssessmentResults(as.memberAssessmentId, userData?.accessToken)
                  setOpen(true)
                }} lastModified={lastModified(as)} />
              }
            })}
            {assessmentsSummary?.find(as => !as.memberAssessmentId) ? <Typography sx={{
              paddingLeft: '20px',
              marginBottom: '20px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              ineHeight: '150%',
              color: 'rgba(0, 0, 0, 0.85)'
            }}>Not Started</Typography> : null}
            {assessmentsSummary?.map(as => {
              if (!as.memberAssessmentId) {
                return <SurveyListItem item={as} onSelect={() => {
                  setSelectedSurvey(as)
                  setType('Not Started')
                  getMemberAssessmentResults(as.memberAssessmentId, userData?.accessToken)
                  setOpen(true)
                }} />
              }
            })}
            {assessmentsSummary?.find(as => as.isComplete) ? <Typography sx={{
              paddingLeft: '20px',
              marginBottom: '20px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              ineHeight: '150%',
              color: 'rgba(0, 0, 0, 0.85)'
            }}>Completed</Typography> : null}
            {assessmentsSummary?.map(as => {
              if (as.isComplete) {
                return <SurveyListItem item={as} onSelect={() => {
                  setSelectedSurvey(as)
                  setType('Completed')
                  getMemberAssessmentResults(as.memberAssessmentId, userData?.accessToken)
                  setOpen(true)
                }} lastModified={lastModified(as)} />
              }
            })}
          </TabPanel>
        </Box>

      </Grid>
      <Dialog open={open} onClose={handleClose} sx={{ '&& .MuiPaper-root': { width: 562, borderRadius: 4 } }}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '120%',
            color: '#1F2937',
            marginBottom: '22px'
          }}>{`${selectedSurvey.assessmentName} Summary`}</Typography>
          <Typography sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '150%',
            color: '#897A7A',
            marginBottom: '18px'
          }}>{lastModified(selectedSurvey) ? `Last updated on ${lastModified(selectedSurvey)}` : 'Not Started'}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs={10}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '22px' }}>
                <Avatar src={`data:image/png;base64,${member?.profilePicture}`} sx={{ width: 32, height: 32, marginRight: 1, marginLeft: 0.75 }} />
                <Typography sx={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 14,
                  marginRight: 1,
                  color: '#003657'
                }} >
                  {`${member?.firstName} ${member?.lastName}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button sx={{
                  background: `#081E3F`,
                  borderRadius: '8px',
                  color: '#FFFFFF',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 16,
                  minWidth: 64,
                  padding: "8px 15px",
                  marginBottom: '34px',
                  textTransform: 'capitalize',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor: `#081E3F`,
                  }

                }} onClick={() => { navigate(`/${selectedSurvey?.assessmentId}/${member?.id}`) }}>
                  {getType(type)}
                  <KeyboardArrowRightIcon fontSize='medium' color='#FFFFFF' style={{ marginLeft: '25px' }} />
                </Button>
              </Box>
            </Grid>

            {surveyResults?.length > 0 ? surveyResults.map(result => <Grid item xs={10}>
              <Typography sx={formLabelInputStyle()}>{result.question}</Typography>
              <OutlinedInput name='email' sx={formInputStyle()} value={result.answers?.join(',')} disabled={true} multiline />
            </Grid>) : null}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '65px', paddingLeft: '65px' }}>

        </DialogActions>
      </Dialog>

    </Grid >);
}

const mapStateToProps = (state) => {
  console.log('App State', state)
  return {
    member: state.members.getMemberProfileSuccess,
    memberLoading: state.members.getMemberProfileLoading,
    assessmentsSummary: state.assessmentsSummary.getAssessmentsSummarySuccess,
    surveyResults: state.memberAssessment.getMemberAssessmentResultsSuccess
  }
}

const mapDispatchToProps = (dispatch) => ({
  addMember: (user) => dispatch(MembersActions.addMember(user)),
  getMemberProfile: (id, accessToken) => dispatch(MembersActions.getMemberProfile(id, accessToken)),
  updateMemberProfile: (thriveMemberId, userData, accessToken) => dispatch(MembersActions.updateMemberProfile(thriveMemberId, userData, accessToken)),
  getAssessmentsSummary: (thriveMemberId, accessToken) => dispatch(AssessmentsSummaryActions.getAssessmentsSummary(thriveMemberId, accessToken)),
  getMemberAssessmentResults: (memberAssessmentId, accessToken) => dispatch(MemberAssessmentActions.getMemberAssessmentResults(memberAssessmentId, accessToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfile)
