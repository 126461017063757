// import Crashes, { ExceptionModel } from 'appcenter-crashes';
/**
 * GET_SURVEY_FAILURE
 */
function* getMemberAssessmentFailure({ error }) {
  // const exceptionModel1 = ExceptionModel.createFromError(error);
  // Crashes.trackError(exceptionModel1, null, null);
  console.log('Success', error)
}

export default getMemberAssessmentFailure