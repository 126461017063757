export const INITIAL_STATE = {
  memberAssessment: {},
  //
  getMemberAssessmentLoading: false,
  getMemberAssessmentSuccess: null,
  getMemberAssessmentFailure: null,
  //
  getMemberAssessmentResultsLoading: false,
  getMemberAssessmentResultsSuccess: null,
  getMemberAssessmentResultsFailure: null,
}
