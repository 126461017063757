import { combineReducers } from 'redux'

import rootSaga from './sagas';
import configureStore from './CreateStore'
// import { reducer as AuthReducer } from './Auth/Reducers'
// import { reducer as AccountReducer } from './Account/Reducers'
// import { reducer as DomainsReducer } from './Domains/Reducers'
// import { reducer as SurveyReducer } from './Survey/Reducers'
import { reducer as AssessmentsSummaryReducer } from './AssessmentsSummary/Reducers'
import { reducer as AssessmentsReducer } from './Assessments/Reducers'
import { reducer as MemberAssessmentReducer } from './MemberAssessment/Reducers'
import { reducer as MembersReducer } from './Members/Reducers'

export default () => {
  const rootReducer = combineReducers({
    // auth: AuthReducer,
    // account: AccountReducer,
    // domains: DomainsReducer,
    // survey: SurveyReducer,
    assessmentsSummary: AssessmentsSummaryReducer,
    assessments: AssessmentsReducer,
    memberAssessment: MemberAssessmentReducer,
    members: MembersReducer
  })

  return configureStore(rootReducer, rootSaga)
}
