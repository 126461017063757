import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './InitialState'
import { AssessmentsTypes } from './Actions'


const getRapidScreenerLoading = (state) => ({
  ...state,
  getRapidScreenerLoading: true,
  getRapidScreenerSuccess: null,
  getRapidScreenerFailure: null
})

const getRapidScreenerSuccess = (state, { data }) => ({
  ...state,
  assessments: data,
  getRapidScreenerLoading: false,
  getRapidScreenerSuccess: data,
  getRapidScreenerFailure: null,
})

const getRapidScreenerFailure = (state, { error }) => ({
  ...state,
  assessments: {},
  getRapidScreenerLoading: false,
  getRapidScreenerSuccess: null,
  getRapidScreenerFailure: error.message
})
//
const getAssessmentLoading = (state) => ({
  ...state,
  getAssessmentLoading: true,
  getAssessmentSuccess: null,
  getAssessmentFailure: null
})

const getAssessmentSuccess = (state, { data }) => ({
  ...state,
  assessment: data,
  getAssessmentLoading: false,
  getAssessmentSuccess: data,
  getAssessmentFailure: null,
})

const getAssessmentFailure = (state, { error }) => ({
  ...state,
  assessment: {},
  getAssessmentLoading: false,
  getAssessmentSuccess: null,
  getAssessmentFailure: error.message
})




export const reducer = createReducer(INITIAL_STATE, {

  [AssessmentsTypes.GET_RAPID_SCREENER]: getRapidScreenerLoading,
  [AssessmentsTypes.GET_RAPID_SCREENER_SUCCESS]: getRapidScreenerSuccess,
  [AssessmentsTypes.GET_RAPID_SCREENER_FAILURE]: getRapidScreenerFailure,
  //
  [AssessmentsTypes.GET_ASSESSMENT]: getAssessmentLoading,
  [AssessmentsTypes.GET_ASSESSMENT_SUCCESS]: getAssessmentSuccess,
  [AssessmentsTypes.GET_ASSESSMENT_FAILURE]: getAssessmentFailure,
})
