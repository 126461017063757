import { all, takeLatest } from 'redux-saga/effects'
import { AssessmentsSummaryTypes } from '../Stores/AssessmentsSummary/Actions'
import AssessmentsSummary from './AssessmentsSummary'

export default function* root() {
  yield all([
    takeLatest(AssessmentsSummaryTypes.GET_ASSESSMENTS_SUMMARY, AssessmentsSummary.getAssessmentsSummary),
    takeLatest(AssessmentsSummaryTypes.GET_ASSESSMENTS_SUMMARY_SUCCESS, AssessmentsSummary.getAssessmentsSummarySuccess),
    takeLatest(AssessmentsSummaryTypes.GET_ASSESSMENTS_SUMMARY_FAILURE, AssessmentsSummary.getAssessmentsSummaryFailure),
  ])
}
