import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './InitialState'
import { MemberAssessmentTypes } from './Actions'


const getMemberAssessmentLoading = (state) => ({
  ...state,
  getMemberAssessmentLoading: true,
  getMemberAssessmentSuccess: null,
  getMemberAssessmentFailure: null
})

const getMemberAssessmentSuccess = (state, { data }) => ({
  ...state,
  memberAssessment: data,
  getMemberAssessmentLoading: false,
  getMemberAssessmentSuccess: data,
  getMemberAssessmentFailure: null,
})

const getMemberAssessmentFailure = (state, { error }) => ({
  ...state,
  memberAssessment: {},
  getMemberAssessmentLoading: false,
  getMemberAssessmentSuccess: null,
  getMemberAssessmentFailure: error.message
})
//
const getMemberAssessmentResultsLoading = (state) => ({
  ...state,
  getMemberAssessmentResultsLoading: true,
  getMemberAssessmentResultsSuccess: null,
  getMemberAssessmentResultsFailure: null
})

const getMemberAssessmentResultsSuccess = (state, { data }) => ({
  ...state,
  getMemberAssessmentResultsLoading: false,
  getMemberAssessmentResultsSuccess: data,
  getMemberAssessmentResultsFailure: null,
})

const getMemberAssessmentResultsFailure = (state, { error }) => ({
  ...state,
  getMemberAssessmentResultsLoading: false,
  getMemberAssessmentResultsSuccess: null,
  getMemberAssessmentResultsFailure: error.message
})




export const reducer = createReducer(INITIAL_STATE, {

  [MemberAssessmentTypes.GET_MEMBER_ASSESSMENT]: getMemberAssessmentLoading,
  [MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_SUCCESS]: getMemberAssessmentSuccess,
  [MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_FAILURE]: getMemberAssessmentFailure,
  //
  [MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_RESULTS]: getMemberAssessmentResultsLoading,
  [MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_RESULTS_SUCCESS]: getMemberAssessmentResultsSuccess,
  [MemberAssessmentTypes.GET_MEMBER_ASSESSMENT_RESULTS_FAILURE]: getMemberAssessmentResultsFailure,
})
