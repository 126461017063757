import { all, takeLatest } from 'redux-saga/effects'
import { MembersTypes } from '../Stores/Members/Actions'
import Members from '../Sagas/Members'

export default function* root() {
  yield all([
    takeLatest(MembersTypes.GET_MEMBERS, Members.getMembers),
    takeLatest(MembersTypes.GET_MEMBERS_SUCCESS, Members.getMembersSuccess),
    takeLatest(MembersTypes.GET_MEMBERS_FAILURE, Members.getMembersFailure),
    //
    takeLatest(MembersTypes.ADD_MEMBER, Members.addMember),
    takeLatest(MembersTypes.ADD_MEMBER_SUCCESS, Members.addMemberSuccess),
    takeLatest(MembersTypes.ADD_MEMBER_FAILURE, Members.addMemberFailure),
    //
    takeLatest(MembersTypes.GET_MEMBER_PROFILE, Members.getMemberProfile),
    takeLatest(MembersTypes.GET_MEMBER_PROFILE_SUCCESS, Members.getMemberProfileSuccess),
    takeLatest(MembersTypes.GET_MEMBER_PROFILE_FAILURE, Members.getMemberProfileFailure),
    //
    takeLatest(MembersTypes.UPDATE_MEMBER_PROFILE, Members.updateMemberProfile),
    takeLatest(MembersTypes.UPDATE_MEMBER_PROFILE_SUCCESS, Members.updateMemberProfileSuccess),
    takeLatest(MembersTypes.UPDATE_MEMBER_PROFILE_FAILURE, Members.updateMemberProfileFailure),
    //
  ])
}
