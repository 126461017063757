const formInputStyle = () => {
  return {
    width: '100%',
    height: 46,
    border: "1px solid #E5E7EB",
    marginBottom: 1.375,
  }
}
const formLabelInputStyle = () => {
  return {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: 1.125,
    letterSpacing: 0.005,
    color: '#475569',
    marginBottom: 0.938
  }
}

export { formInputStyle, formLabelInputStyle }