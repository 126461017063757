/**
 * GET_MEMBER_PROFILE_SUCCESS
 */
function* getMemberProfileSuccess({ user }) {
  console.log('Success', user)
  // NavigationService.navigate('PathSelection')
  // NavigationService.navigate('Dashboard')
}

export default getMemberProfileSuccess
