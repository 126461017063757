import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Avatar, Backdrop, Typography, Menu, MenuItem, Chip, FormGroup, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, OutlinedInput, InputAdornment, Button, Pagination, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MembersActions from '../../Stores/Members/Actions'
import { Form, Field } from 'react-final-form'
import isEmpty from '../../Helpers/isEmpty'
import isValidDate from '../../Helpers/isValidDate'
import { useNavigate } from 'react-router-dom'
import { getLoggedUserData } from '../../Services/MsalServices'
import { useMsal, useAccount } from '@azure/msal-react';
import { common } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import LabeledInput from '../../Components/LabeledInput'

const filters = ['First Name', 'Last Name', 'Date of Birth', 'Phone Number', 'Email'];

const Members = ({ getMembers, members, addMember, addMemberSuccess, addMemberLoading, getMembersLoading }) => {
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [firstNameFilter, setFirstNameFilter] = useState('')
  const [lastNameFilter, setLastNameFilter] = useState('')
  const [emailFilter, setEmailFilter] = useState('')
  const [dateOfBirthFilter, setDateOfBirthFilter] = useState('')
  const [phoneNumberFilter, setPhoneNumberFilter] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('Last Name')
  const [searchValue, setSearchValue] = useState('')
  const [totalPageCount, setTotalPageCount] = useState(10)
  const [member, setMember] = useState({})
  const navigate = useNavigate();
  const [added, setAdded] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {});
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (!userData && inProgress === InteractionStatus.None) {
      getLoggedUserData(instance, account, InteractionRequiredAuthError, setUserData)
    }
  }, [instance, account])

  useEffect(() => {
    const today = new Date()
    const tokenHasExp = userData?.expiresOn?.getTime() < today?.getTime()
    if (tokenHasExp) {
      instance.logoutRedirect().catch((error) => console.log(error));
    }
    if (userData) {
      getMembers('', firstNameFilter, lastNameFilter, emailFilter, dateOfBirthFilter, phoneNumberFilter, pageNumber, pageSize, userData.accessToken)
    }
  }, [firstNameFilter, lastNameFilter, emailFilter, dateOfBirthFilter, phoneNumberFilter, pageNumber, pageSize, userData])

  useEffect(() => {
    if (members?.pagination) {
      const pagination = JSON.parse(members?.pagination)
      setTotalPageCount(pagination?.TotalPageCount)
    }
  }, [members])

  useEffect(() => {
    if (added && addMemberSuccess) {
      navigate(`memberProfile/${addMemberSuccess.id}/true`)
      setAdded(false)
    }
  }, [added, addMemberSuccess])

  const handleClickOpen = () => {
    setStep(0)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleOpenFilterMenu = (event) => {
    setSearchValue('')
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const handleSelectFilter = (value) => {
    setFirstNameFilter('')
    setLastNameFilter('')
    setEmailFilter('')
    setPhoneNumberFilter('')
    setDateOfBirthFilter('')
    setSelectedFilter(value)
    handleCloseFilterMenu()
  }

  const handleStepNext = (form) => {
    const { errors, values } = form.getState()
    if (step === 0) {
      if (!errors.firstName &&
        !errors.lastName &&
        !errors.dateOfBirth) {
        setStep(step + 1)
      }
    }

    if (step === 1) {
      if (
        !errors.addressLine1 &&
        !errors.city &&
        !errors.zipCode
      ) {
        setStep(step + 1)
      }
    }
    if (step === 2) {
      if (
        !errors.phoneNumber &&
        !errors.email) {
        handleSubmit(values)
      }
    }
  }
  const handleStepPrev = () => {
    setStep(step - 1)
  }


  const handleSubmit = (values) => {
    console.log(values)
    addMember(values, userData.accessToken)
    handleClose()
    if (addMemberSuccess) {
      setAdded(true)
    }
  }

  const validate = (values) => {
    const errors = {}
    if (isEmpty(values.firstName)) {
      errors.firstName = 'Incorrect entry.'
    }

    if (isEmpty(values.lastName)) {
      errors.lastName = 'Incorrect entry.'
    }

    if (isEmpty(values.dateOfBirth)) {
      errors.dateOfBirth = 'Incorrect entry.'
    } else {
      const dob = new Date(values.dateOfBirth)
      if (`${dob}` === 'Invalid Date' || !isValidDate(values.dateOfBirth)) {
        errors.dateOfBirth = 'Incorrect entry.'
      } else if (dob.getFullYear() < 1925) {
        errors.dateOfBirth = 'Incorrect entry.'
      } else {
        const today = new Date()
        let age = today.getFullYear() - dob.getFullYear()
        const m = today.getMonth() - dob.getMonth()

        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--
        }

        if (age < 18) {
          errors.dateOfBirth = 'Incorrect entry.'
        }
      }
    }

    if (isEmpty(values.addressLine1)) {
      errors.addressLine1 = 'Incorrect entry.'
    }
    if (isEmpty(values.city)) {
      errors.city = 'Incorrect entry.'
    }
    // if (isEmpty(values.state)) {
    //   errors.state = 'State is required.'
    // }
    if (isEmpty(values.zipCode)) {
      errors.zipCode = 'Incorrect entry.'
    } else if (
      (values.zipCode.includes('-') && values.zipCode.length !== 10) ||
      (!values.zipCode.includes('-') && values.zipCode.length !== 5)
    ) {
      errors.zipCode = 'Incorrect entry.'
    }
    if (isEmpty(values.phoneNumber)) {
      errors.phoneNumber = 'Incorrect entry.'
    }
    if (isEmpty(values.email)) {
      errors.email = 'Incorrect entry.'
    }

    return errors
  }

  const onSearch = (value) => {
    setPageNumber(1)
    switch (selectedFilter) {
      case 'First Name':
        setFirstNameFilter(value)
        break
      case 'Last Name':
        setLastNameFilter(value)
        break
      case 'Date of Birth':
        setDateOfBirthFilter(value)
        break
      case 'Email':
        setEmailFilter(value)
        break
      case 'Phone Number':
        setPhoneNumberFilter(value)
        break
      default: getMembers('', '', '', '', '', '', 1, 10, userData.accessToken)

    }
  }

  if (getMembersLoading || addMemberLoading) {
    return <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={getMembersLoading || addMemberLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  }

  return <Grid container spacing={2} sx={{
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10
  }}>
    <Grid container direction="row" xs={12} sx={{
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: 1.375
    }}>
      <Button variant="contained" onClick={handleClickOpen} sx={{
        background: '#081E3F',
        borderRadius: 0.9,
        width: 135,
        height: 32,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        textTransform: 'none',
        paddingX: 0.625,
        marginRight: 0.875
      }} startIcon={<PermIdentityIcon />}>
        Add Member
      </Button>
      <OutlinedInput
        id="input-with-icon-adornment"
        sx={{
          width: 285,
          marginRight: '10px',
          '& .MuiOutlinedInput-input': {
            paddingY: 0.6,
          },
          paddingRight: 0

        }}
        value={searchValue}
        placeholder={selectedFilter}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            console.log(e.target.value)
            onSearch(e.target.value)
          }
        }}
        endAdornment={
          <InputAdornment position="center" sx={{
            backgroundColor: '#081E3F',
            padding: '16px 14px',
            border: '1px solid #081E3F',
            borderTopRightRadius: (theme) =>
              theme.shape.borderRadius + 'px',
            borderBottomRightRadius: (theme) =>
              theme.shape.borderRadius + 'px',
          }} onClick={() => { onSearch(searchValue) }} >
            <SearchIcon sx={{ color: '#FFFFFF' }} fontSize="small" />
          </InputAdornment>
        }
      />
      <Button variant="outlined" onClick={handleOpenFilterMenu} sx={{
        paddingX: 0.625,
        paddingY: 0.4,
        minWidth: 32,
        borderColor: '#E5E7EB',
        '&:hover': {
          borderColor: '#E5E7EB'
        }
      }} >
        <KeyboardArrowDownIcon htmlColor={'#374151'} />
      </Button>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElFilter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElFilter)}
        onClose={handleCloseFilterMenu}
      >
        {filters.map((filter) => (
          <MenuItem key={filter} onClick={(e, v) => handleSelectFilter(e.target.innerText)} value={filter}>
            <Typography textAlign="center">{filter}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
    <Grid container direction="row" xs={12} sx={{
      justifyContent: 'flex-start',
      alignItems: 'center'
    }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size='small' sx={{
                border: "1px solid #d4d4d4",
              }}>Picture</TableCell>
              <TableCell align="left" sx={{
                border: "1px solid #d4d4d4"
              }}>Name</TableCell>
              <TableCell align="left" sx={{
                border: "1px solid #d4d4d4"
              }}>Alerts</TableCell>
              <TableCell align="left" sx={{
                border: "1px solid #d4d4d4"
              }}>Overall Score</TableCell>
              <TableCell align="left" sx={{
                border: "1px solid #d4d4d4"
              }}>Last Contact</TableCell>
              <TableCell align="left" sx={{
                border: "1px solid #d4d4d4"
              }}>Pending Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.data?.map((row) => (
              <TableRow
                key={row.name}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => navigate(`memberProfile/${row.id}/false`)}
              >
                <TableCell component="th" scope="row" size='small' sx={{
                  borderRight: "1px solid #d4d4d4"
                }}>
                  <Avatar src={`data:image/png;base64,${row.profilePicture}`} sx={{ width: 32, height: 32 }} />
                </TableCell>
                <TableCell align="left" sx={{
                  borderRight: "1px solid #d4d4d4"
                }}>{`${row.firstName} ${row.lastName}`}</TableCell>
                <TableCell align="left" sx={{
                  borderRight: "1px solid #d4d4d4"
                }}> <Chip label={row.alerts || 'Risk'} variant='outlined' size="small" sx={{
                  color: '#F5222D',
                  borderColor: '#FFA39E',
                  backgroundColor: '#FFF1F0',
                  borderRadius: 0.7,
                }} /></TableCell>
                <TableCell align="left" sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRight: "1px solid #d4d4d4"

                }}>{row.score || 30}<CircleIcon fontSize='16' color='success' sx={{ marginBottom: 0.4 }} /> </TableCell>
                <TableCell align="left" sx={{
                  borderRight: "1px solid #d4d4d4"
                }}>{row.lastContact || '02/03/2023'}</TableCell>
                <TableCell align="left">{row.pendingActions || "Referal Pending"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Grid>
    <Grid container direction="row" xs={12} sx={{
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: 1.375,
      paddingTop: 1.375
    }}>
      <Pagination sx={{
        '& .MuiPaginationItem-root': {
          backgroundColor: 'transparent'
        }
      }} count={totalPageCount} page={pageNumber} variant="outlined" shape="rounded" color='primary' onChange={(e, v) => { setPageNumber(v) }} />
    </Grid>
    <Dialog open={open} onClose={handleClose} sx={{ '&& .MuiPaper-root': { maxWidth: 562, borderRadius: 4 } }}>
      <DialogTitle sx={{ textAlign: 'center' }}>Add Member</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{}}
        // validateOnBlur={true}
        render={({ handleSubmit, form }) => {
          console.log(form.getState())
          return <><DialogContent>
            {step == 0 ? <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <LabeledInput name='firstName' label='First Name' />
              <LabeledInput name='lastName' label='Last Name' />
              <LabeledInput name='dateOfBirth' label='Date of Birth' />
            </Grid> : null}
            {step == 1 ?
              <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <LabeledInput name='addressLine1' label='Address' />
                <LabeledInput name='suite' label='Suite/ Apt' />
                <LabeledInput name='city' label='City' />
                <LabeledInput name='state' label='State' />
                <LabeledInput name='zipCode' label='Zip Code' />
              </Grid> : null}
            {step == 2 ?
              <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <LabeledInput name='phoneNumber' label='Phone Number' />
                <LabeledInput name='email' label='Email' />
                <Field name="terms">
                  {({ input, meta }) => (
                    <Grid item xs={10}>
                      <FormGroup>
                        <FormControlLabel sx={{
                          '& .MuiTypography-root': {
                            color: '#464646',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 18,
                          }

                        }} control={<Checkbox defaultChecked {...input} />} label="I ackowledge that the member has verbally consented to participate in the FIU Thrive Pilot study." />
                      </FormGroup>
                    </Grid>
                  )}
                </Field>
              </Grid> : null}
          </DialogContent>
            <DialogActions sx={{ paddingRight: '65px', paddingLeft: '65px' }}>
              <Grid container>
                <Grid item xs={6}>
                  {step > 0 ? <Button onClick={handleStepPrev} sx={{
                    color: '#003657',
                    fontFamily: 'Work Sans',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 16,
                  }} startIcon={<KeyboardBackspaceIcon />}>
                    Back
           </Button> : null}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button sx={{
                    background: `linear-gradient(96.32deg, #17A7B1 0%, #99DCDF 103.77%)`,
                    borderRadius: 11.25,
                    color: '#FFFFFF',
                    fontFamily: 'Work Sans',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 16,
                    minWidth: 64,
                    padding: "8px 15px",
                    marginBottom: '34px'
                  }} onClick={() => handleStepNext(form)}>Next</Button>
                </Grid>
              </Grid>


            </DialogActions>
          </>
        }} />
    </Dialog>
  </Grid >

}

const mapStateToProps = (state) => {
  console.log('State', state)
  return {
    members: state.members.getMembersSuccess,
    addMemberSuccess: state.members.addMemberSuccess,
    addMemberLoading: state.members.addMemberLoading,
    getMembersLoading: state.members.getMembersLoading
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMembers: (id, firstName, lastName, email, dateOfBirth, phoneNumber, pageNumber, pageSize, accessToken) => dispatch(MembersActions.getMembers(id, firstName, lastName, email, dateOfBirth, phoneNumber, pageNumber, pageSize, accessToken)),
  addMember: (user, accessToken) => dispatch(MembersActions.addMember(user, accessToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Members)
