import getMemberAssessment from './GetMemberAssessment/getMemberAssessment'
import getMemberAssessmentSuccess from './GetMemberAssessment/getMemberAssessmentSuccess'
import getMemberAssessmentFailure from './GetMemberAssessment/getMemberAssessmentFailure'

import getMemberAssessmentResults from './GetMemberAssessmentResults/getMemberAssessmentResults'
import getMemberAssessmentResultsSuccess from './GetMemberAssessmentResults/getMemberAssessmentResultsSuccess'
import getMemberAssessmentResultsFailure from './GetMemberAssessmentResults/getMemberAssessmentResultsFailure'


export default {
  //
  getMemberAssessment,
  getMemberAssessmentSuccess,
  getMemberAssessmentFailure,
  //
  getMemberAssessmentResults,
  getMemberAssessmentResultsSuccess,
  getMemberAssessmentResultsFailure,
}
