import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist'
// import AsyncStorage from '@react-native-async-storage/async-storage'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['MembersReducer'] // Blacklist state that we do not need/want to persist
}


export default (rootReducer, rootSaga) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  // Redux persist
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, applyMiddleware(...middlewares));
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}