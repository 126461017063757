import { all, fork, takeLatest } from 'redux-saga/effects'

// import AuthSaga from './AuthSaga'
// import AccountSaga from './AccountSaga'
// import DomainsSaga from './DomainsSaga'
// import SurveySaga from './SurveySaga'
import AssessmentsSummarySaga from './AssessmentsSummarySaga'
import AssessmentsSaga from './AssessmentsSaga'
import MemberAssessmentSaga from './MemberAssessmentSaga'
import MembersSaga from './MembersSaga'

//Do StartUp Saga later

export default function* root() {
  yield all([
    // Run the startup saga when the application starts
    // takeLatest(StartupTypes.STARTUP, startup),
    // fork(AuthSaga),
    // fork(AccountSaga),
    // fork(DomainsSaga),
    // fork(SurveySaga),
    fork(AssessmentsSummarySaga),
    fork(AssessmentsSaga),
    fork(MemberAssessmentSaga),
    fork(MembersSaga)
  ])
}
