import { put } from 'redux-saga/effects'
// import { authorize } from 'react-native-app-auth'
// import jwt_decode from "jwt-decode";
import { insertUsers } from '../../../APIs/Users'
import MembersActions from '../../../Stores/Members/Actions'

function* addMember({ user, accessToken }) {
  try {
    yield put(MembersActions.addMemberLoading())

    if (user && accessToken) {
      const respThive = yield insertUsers(user, accessToken)
      yield put(MembersActions.addMemberSuccess(respThive?.data))
    }
  } catch (error) {
    console.log(error)
    yield put(MembersActions.addMemberFailure(error))
  }
}

export default addMember
