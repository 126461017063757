export const INITIAL_STATE = {
  members: {},
  //
  getMembersLoading: false,
  getMembersSuccess: null,
  getMembersFailure: null,
  //
  addMemberLoading: false,
  addMemberSuccess: null,
  addMemberFailure: null,
  //
  getMemberProfileLoading: false,
  getMemberProfileSuccess: null,
  getMemberProfileFailure: null,
  //
  updateMemberProfileLoading: false,
  updateMemberProfileSuccess: null,
  updateMemberProfileFailure: null,

}
