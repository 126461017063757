import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    //
    getMembers: ['id', 'firstName', 'lastName', 'email', 'dateOfBirth', 'phoneNumber', 'pageNumber', 'pageSize', 'accessToken'], // GET_MEMBERS
    getMembersLoading: null, // GET_MEMBERS_LOADING
    getMembersSuccess: ['data'], // GET_MEMBERS_SUCCESS
    getMembersFailure: ['error'], // GET_MEMBERS_FAILURE
    //
    addMember: ['user', 'accessToken'], // ADD_MEMBER
    addMemberLoading: null, // ADD_MEMBER_LOADING
    addMemberSuccess: ['user'], // ADD_MEMBER_SUCCESS
    addMemberFailure: ['error'], // ADD_MEMBER_FAILURE
    //
    getMemberProfile: ['id', 'accessToken'], // GET_MEMBER_PROFILE
    getMemberProfileLoading: null, // GET_MEMBER_PROFILE_LOADING
    getMemberProfileSuccess: ['user'], // GET_MEMBER_PROFILE_SUCCESS
    getMemberProfileFailure: ['error'], // GET_MEMBER_PROFILE_FAILURE
    //
    updateMemberProfile: ['thriveMemberId', 'userData', 'accessToken'], // UPDATE_MEMBER_PROFILE
    updateMemberProfileLoading: null, // UPDATE_MEMBER_PROFILE_LOADING
    updateMemberProfileSuccess: ['user'], // UPDATE_MEMBER_PROFILE_SUCCESS
    updateMemberProfileFailure: ['error'], // UPDATE_MEMBER_PROFILE_FAILURE
  },
  { prefix: 'MEMBERS_' }
)

export const MembersTypes = Types

export default Creators