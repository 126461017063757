import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap"
import { Provider } from 'react-redux'
import Assessment from './Containers/Assessment';
import Members from './Containers/Members';
import MemberProfile from './Containers/MemberProfile';
import NavBar from './Components/NavBar'
import { ProSidebarProvider } from 'react-pro-sidebar';
import SideBar from './Components/SideBar'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react'
import createStore from './Stores'
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import SignIn from './Containers/SignIn'
const drawerWidth = 300;


const { store, persistor } = createStore()

function App() {
  const currentRoute = useLocation()
  console.log('Route', currentRoute.pathname)
  if (currentRoute.pathname.includes('/app')) {
    return <Provider store={store}  ><Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Routes>
        <Route path="/app/:assessmentId/:thriveMemberId" element={<Assessment />} exact />
      </Routes>
    </Box>
    </Provider>
  }
  return (
    <Provider store={store}  >
      <PersistGate loading={null} persistor={persistor}>
        <AuthenticatedTemplate>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <NavBar />
            <SideBar />
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
              <Routes>
                <Route path="/:assessmentId/:thriveMemberId" element={<Assessment />} exact />
                <Route path="/" element={<Members />} exact />
                <Route path="/memberProfile/:id/:added" element={<MemberProfile />} exact />
              </Routes>
            </Box>
          </Box>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignIn />
        </UnauthenticatedTemplate>
      </PersistGate>
    </ Provider >
  );
}

export default App;
