/**
 * GET_MEMBERS_SUCCESS
 */
function* getMembersSuccess({ data }) {
  console.log('Success Members', data)
  // NavigationService.navigate('PathSelection')
  // NavigationService.navigate('Dashboard')
}

export default getMembersSuccess
