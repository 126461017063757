import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMsal, useIsAuthenticated, useAccount, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { loginRequest } from '../../Configs/authConfig'




const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const drawerWidth = 300;
const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const { instance } = useMsal()

  let activeAccount;

  if (instance) {
    activeAccount = instance?.getActiveAccount();
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting = '') => {
    if (setting === 'Logout') {
      handleLogoutRedirect()
    }
    setAnchorElUser(null);
  };


  console.log(activeAccount)
  return <AppBar
    position="fixed"
    sx={{
      width: { sm: `calc(100% - ${drawerWidth}px)` },
      ml: { sm: `${drawerWidth}px` },
      background: '#FFFFFF'
    }}
  >
    <Toolbar sx={{
      justifyContent: 'flex-end'
    }}>
      <Box sx={{ flexGrow: 0 }}>

        <IconButton onClick={() => { }} sx={{ p: 0 }}>
          <Avatar alt={activeAccount?.name} sx={{ bgcolor: '#004068' }}>
            {activeAccount?.name[0]}
          </Avatar>
        </IconButton>
        <Typography display='inline' sx={{
          color: '#000',
          fontFamily: 'Manrope',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 20,
          marginLeft: 0.75
        }}>{activeAccount?.name || 'Monica'}</Typography>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <KeyboardArrowDownIcon sx={{ color: '#000000' }} />
          </IconButton>
        </Tooltip>

        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Toolbar>
  </AppBar>
}

export default NavBar