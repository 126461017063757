import { thriveDevReq, thriveDevUrl } from '..'
import { getConfigs } from '../apiHelpers'
import axios from 'axios'


// export const getAssessmentsSummaryAPI = (thriveMemberId, token) => {
//   return thriveDevReq.get(`/AssessmentsSummary/${thriveMemberId}`, {}, getConfigs(token))
// }
export const getAssessmentsSummaryAPI = async (thriveMemberId, token) => {
  const response = await axios({
    "url": `${thriveDevUrl}/AssessmentsSummary/${thriveMemberId}`,
    "method": "GET",
    "headers": getConfigs(token)
  })
    .then(response => {

      return response
    })
    .catch(e => {
      console.log(e)
    })
  return response
}

