

export const getLoggedUserData = (instance, account, InteractionRequiredAuthError, setData) => {
  console.log('getting token')
  const accessTokenRequest = {
    scopes: ["https://FIUCOMB2CDEV.onmicrosoft.com/thrive-api/read"],
    account: account
  }
  if (account) {
    instance.acquireTokenSilent(accessTokenRequest).then((response) => {
      if (response) {
        console.log('token 11: ', response)
        setData(response)
      }

    }).catch(error => {
      // instance.logoutRedirect().catch((error) => console.log(error));
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest)
      }
      console.log(error)
    })
  }
}