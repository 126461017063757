export const INITIAL_STATE = {
  assessments: {},
  //
  getRapidScreenerLoading: false,
  getRapidScreenerSuccess: null,
  getRapidScreenerFailure: null,
  //
  getAssessmentLoading: false,
  getAssessmentSuccess: null,
  getAssessmentFailure: null,
}
